// extracted by mini-css-extract-plugin
export var dropzoneContainer = "Dropzone-module--dropzone-container--k6ktK";
export var dropzoneContentButton = "Dropzone-module--dropzone-content-button--+9iZK";
export var dropzoneContentLabel = "Dropzone-module--dropzone-content-label--zzaaY";
export var dropzoneErrorMessage = "Dropzone-module--dropzone-error-message--21bXg";
export var dropzoneInfoText = "Dropzone-module--dropzone-info-text--hqyve";
export var dropzoneInput = "Dropzone-module--dropzone-input--OTPQL";
export var dropzoneLabel = "Dropzone-module--dropzone-label--v1Ab6";
export var dropzoneLabelMobile = "Dropzone-module--dropzone-label-mobile--9nb4w";
export var dropzoneLoader = "Dropzone-module--dropzone-loader--PKBeI";
export var dropzonePreview = "Dropzone-module--dropzone-preview--685OK";
export var dropzonePreviewDelete = "Dropzone-module--dropzone-preview-delete--QiJul";
export var dropzonePreviewDeleteIcon = "Dropzone-module--dropzone-preview-delete-icon--IO1RI";
export var dropzonePreviewDeleteIconHover = "Dropzone-module--dropzone-preview-delete-icon-hover--li-Ub";
export var dropzonePreviewIsLoading = "Dropzone-module--dropzone-preview-is-loading--W3roe";
export var dropzonePreviewMobile = "Dropzone-module--dropzone-preview-mobile--VCXJk";
export var dropzonePreviewMobileImage = "Dropzone-module--dropzone-preview-mobile-image--Nz2Ul";
export var dropzonePreviewMobileSmall = "Dropzone-module--dropzone-preview-mobile-small--1bahL";
export var dropzoneWrapper = "Dropzone-module--dropzone-wrapper--0uocS";
export var isActive = "Dropzone-module--is-active--WYsKt";