import React, { useEffect, useState } from 'react';

// === Components === //
import Input from '@components/Input/Input';
import Select from '@components/Select/Select';
import Checkbox from '@components/Checkbox/Checkbox';
import Dropzone from '@components/Dropzone/Dropzone';
import Button from '@components/Button/Button';
import SimpleLoader from '@components/SimpleLoader/SimpleLoader';

// === Helpers === //
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import axios from 'axios';
import md5 from 'md5-hash';
import * as yup from 'yup';

// === Styles === //
import * as styles from '@components/Form/Form.module.scss';

// === Types === //
import { FormValues, IFData, IImage } from '@components/Form/Form.d';
import { ISelectOption, ISelectOptionGrouped } from '@components/Select/Select.d';
import { read } from 'fs';

const Form = () => {
  const [offices, setOffices] = useState<ISelectOptionGrouped[]>([]);
  const [charitableFocusArea, setCharitableFocusArea] = useState<ISelectOption[]>([]);
  const [images, setImages] = useState<IImage[]>([]);
  const [imageError, setImageError] = useState<boolean>(false);
  const [uploadImgId, setUploadImgId] = useState<number | null>(null);
  const [imagesIds, setImagesIds] = useState<number[]>([]);
  const [formData, setFormData] = useState<IFData | null>(null);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const schema = yup
    .object({
      name: yup
        .string()
        .max(50, 'Please enter no more than 50 characters')
        .required('Please enter name'),
      email: yup
        .string()
        .email('Please enter correct e-mail address')
        .required('Please enter e-mail'),
      office: yup.string().required('Please select office location'),
      focusArea: yup.string().required('Please select charitable focus area'),
      gdpr: yup.bool().oneOf([true], 'Field must be checked'),
      supportedOrganization: yup.string().max(50, 'Please enter no more than 50 characters'),
      tellUsAboutYourDay: yup.string().max(50, 'Please enter no more than 50 characters'),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      email: '',
      office: '',
      supportedOrganization: '',
      focusArea: '',
      gdpr: false,
      tellUsAboutYourDay: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (!imageError) {
      const fData: IFData = {
        name: data.name,
        email: data.email,
        gdpr: data.gdpr ? 1 : 0,
        office_id: data.office,
        charitable_focus_area_id: data.focusArea,
        supported_organisation: data.supportedOrganization,
        tell_us_about_your_day: data.tellUsAboutYourDay,
      };
      setFormData(fData);
      setSubmitLoading(true);

      images.forEach((d: IImage, index: number) => {
        const reader: any = new FileReader();
        const filename = `${md5(`${d.file.name}-${d.meta.uploadedDate}`)}.${
          d.meta.type.split('image/')[1]
        }`;

        reader.readAsArrayBuffer(d.file);
        reader.onload = function () {
          //console.log('before image fetch');
          fetch(`${process.env.API_URL}/file/upload/node/application/field_images`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/octet-stream',
              Accept: 'application/json',
              'Content-Disposition': `file; filename=\"${filename}\"\r`,
            },
            body: reader.result,
          })
            .then((response) => response.json())
            .then((json) => {
              //console.log('after fetch image, befre if');
              if (json && json.fid) {
                //console.log('after fetch image, inside if');
                setUploadImgId(json.fid[0].value);
              }
            })
            .catch((error) => {
              console.error(error);
              setSubmitLoading(false);
            });
        };

        reader.onerror = function () {
          console.error(reader.error);
          setSubmitLoading(false);
        };
      });
    }
  };

  const handleUploadImage = (data, action: string) => {
    if (action === 'add') {
      setImages((oldState) => [...oldState, data]);
    } else if (action === 'remove') {
      const removedIndex = images.findIndex(
        (img) =>
          img.meta.name === data.meta.name && img.meta.uploadedDate === data.meta.uploadedDate
      );

      setImages(images.filter((img, index) => index !== removedIndex));
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.API_URL}/api/offices`)
      .then((response) => {
        if (response && response.status === 200) {
          const regionsList = [...new Set([...response.data.map((item) => item.region)])];
          setOffices([
            ...regionsList
              .map((region) => ({
                label: region,
                options: [
                  ...response.data
                    .filter((item) => item.region === region)
                    .map((filteredItem) => ({
                      label: filteredItem.name,
                      value: filteredItem.office_id,
                      office: filteredItem,
                    })),
                ].sort((a, b) =>
                  a.label.toLowerCase().trim().localeCompare(b.label.toLowerCase().trim())
                ),
              }))
              .sort((a, b) =>
                a.label.toLowerCase().trim().localeCompare(b.label.toLowerCase().trim())
              ),
          ]);
        }
      })
      .catch(function (error) {
        console.error(error);
      });

    axios
      .get(`${process.env.API_URL}/api/charitable-focus-area`)
      .then((response) => {
        if (response && response.status === 200) {
          setCharitableFocusArea(
            [...response.data]
              .map((item) => ({
                label: item.name,
                value: item.id,
              }))
              .sort((a, b) =>
                a.label.toLowerCase().trim().localeCompare(b.label.toLowerCase().trim())
              )
          );
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    setImageError(false);
  }, [images]);

  useEffect(() => {
    //console.log('useEffect start');
    if (uploadImgId) {
      //console.log('useEffect, if 1');
      setImagesIds([...imagesIds, uploadImgId]);
    }

    if (uploadImgId && images.length > 0 && imagesIds.length === images.length - 1) {
      //console.log('useEffect, if2 before axios');
      axios
        .post(`${process.env.API_URL}/api/application_form`, {
          ...formData,
          images: [...imagesIds, uploadImgId],
        })
        .then((response) => {
          //console.log('useEffect, if axios');
          if (
            response &&
            response.status === 200 &&
            response.data === 'ok' &&
            typeof window !== 'undefined'
          ) {
            //console.log('useEffect, inside if axios');
            window.location.href = '/thank-you';
          }
          setSubmitLoading(false);
        })
        .catch(function (error) {
          setSubmitLoading(false);
          console.error(error);
        });
    }
  }, [uploadImgId]);

  return (
    <div className={cn('form-wrapper')}>
      <div className={styles.form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="name"
            label="Name *"
            placeholder="John Smith"
            register={register('name')}
            errorMessage={errors.name?.message}
          />

          <Input
            name="email"
            label="VMLY&R network email address *"
            placeholder="john.smith@vmlyr.com"
            register={register('email')}
            errorMessage={errors.email?.message}
          />

          <Select
            name="office"
            label="Office location (or alignment) *"
            placeholder="select from list"
            options={offices}
            onChange={(option: ISelectOption) => {
              setValue('office', option.value);
            }}
            errorMessage={errors.office?.message}
          />

          <Input
            name="supportedOrganization"
            label="Community partner supported"
            placeholder="organization name"
            register={register('supportedOrganization')}
            errorMessage={errors.supportedOrganization?.message}
          />

          <Input
            name="tellUsAboutYourDay"
            label="Tell us about your Foundation Day experience"
            placeholder="ex: # of meals served, trees planted, etc."
            register={register('tellUsAboutYourDay')}
            errorMessage={errors.tellUsAboutYourDay?.message}
          />

          <Select
            name="focusArea"
            label="Charitable Focus Area  *"
            placeholder="select from list"
            options={charitableFocusArea}
            onChange={(option: ISelectOption) => {
              setValue('focusArea', option.value);
            }}
            errorMessage={errors.focusArea?.message}
          />

          <Dropzone
            label="Upload Content *"
            labelMobile="Add images from gallery *"
            error={imageError}
            errorMessage="Please add at least one image"
            images={images}
            handleUploadImage={handleUploadImage}
          />

          <Checkbox
            name="gdpr"
            label={[
              'Accept ',
              <a href="https://www.vmlyr.com/terms-and-conditions" target="_blank">
                terms and conditions
              </a>,
              ' *',
            ]}
            register={register('gdpr')}
            errorMessage={errors.gdpr?.message}
          />

          <div className={styles.formButton}>
            <Button
              handleClick={() => {
                if (images.length === 0) {
                  setImageError(true);
                }
              }}
              type="submit"
              loading={submitLoading}
            >
              {submitLoading ? <SimpleLoader variant="form" /> : 'Submit'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
