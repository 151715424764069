import React, { CSSProperties, useEffect } from 'react';

// === Assets === //
import iconDelete from '@assets/images/icon-delete.svg';

// === Components === //
import Dropzone, { IPreviewProps, IStyleCustomization } from 'react-dropzone-uploader';

// === Helpers === //
import cn from 'classnames';

// === Styles === //
import * as styles from '@components/Dropzone/Dropzone.module.scss';

// === Types === //
import { IDropzoneExtend, IDropzonePreview } from '@components/Dropzone/Dropzone.d';

const DropzonePreview = ({
  meta,
  fileWithMeta,
  handleUploadImage,
  setLimitError,
}: IDropzonePreview) => {
  const { previewUrl, status } = meta;
  const { remove } = fileWithMeta;

  useEffect(() => {
    if (status === 'error_file_size') {
      handleUploadImage({ meta }, 'remove');
      setLimitError(true);
      remove();
    }
  }, [status]);

  return (
    <div
      className={cn(
        styles.dropzonePreviewMobileImage,
        status !== 'done' && status !== 'error_file_size' && styles.dropzonePreviewIsLoading
      )}
      style={{ backgroundImage: `url(${previewUrl})` }}
    >
      {(status === 'done' || status === 'error_file_size') && (
        <button
          type="button"
          className={styles.dropzonePreviewDelete}
          onClick={() => {
            remove();
            handleUploadImage({ meta }, 'remove');
            setLimitError(false);
          }}
        >
          <span className={styles.dropzonePreviewDeleteIcon}>
            <img src={iconDelete} alt="" />
          </span>
        </button>
      )}
    </div>
  );
};

const DropzoneMobile = ({
  labelMobile,
  errorMessage,
  error,
  images,
  loading,
  infoText,
  limitErrorText,
  limitError,
  setLimitError,
  handleUploadImage,
  handleChangeStatus,
}: IDropzoneExtend) => {
  const imagesLimit = 10;
  const customStyles: IStyleCustomization<CSSProperties> = {
    dropzone: {
      width: `calc(${images.length + 1} * 142px)`,
      border: 'none',
      borderRadius: 0,
      position: 'relative',
      display: images.length <= 1 ? 'flex' : 'grid',
      gridTemplateColumns: `repeat(${images.length + 1}, 1fr)`,
      gap: 14,
      flexDirection: 'row',
      justifyContent: 'center',
      paddingBottom: 10,
    },
    inputLabelWithFiles: {
      width: 128,
      height: 128,
      border: `1px dashed ${error ? '#EE4044' : '#000000'}`,
      fontFamily: 'SnowflakeSansBold, sans-serif',
      fontSize: 32,
      color: '#000000',
      backgroundColor: 'transparent',
      paddingTop: 6,
      margin: 0,
    },
    inputLabel: {
      width: 128,
      height: 128,
      margin: 'auto',
      border: `1px dashed ${error ? '#EE4044' : '#000000'}`,
      fontFamily: 'SnowflakeSansBold, sans-serif',
      fontSize: 32,
      color: '#000000',
      paddingTop: 6,
      position: 'static',
    },
  };

  return (
    <div className={styles.dropzoneWrapper}>
      {labelMobile && labelMobile !== '' && (
        <label className={cn(styles.dropzoneLabel, styles.dropzoneLabelMobile)}>
          {labelMobile}
        </label>
      )}

      <div className={styles.dropzoneContainer}>
        <Dropzone
          key="dropzoneMobile"
          onChangeStatus={(status) => {
            handleChangeStatus(status);

            if (status.meta.status === 'done') {
              setLimitError(false);
            }
          }}
          accept="image/*"
          multiple={true}
          maxSizeBytes={15000000} // 15mb
          maxFiles={imagesLimit}
          inputContent={() => '+'}
          inputWithFilesContent={() => '+'}
          PreviewComponent={(props: IPreviewProps) => (
            <DropzonePreview
              {...props}
              handleUploadImage={handleUploadImage}
              setLimitError={setLimitError}
            />
          )}
          styles={customStyles}
        />
      </div>
      <p className={styles.dropzoneInfoText}>{infoText}</p>

      {limitError && !error && <p className={styles.dropzoneErrorMessage}>{limitErrorText}</p>}
      {error && <p className={styles.dropzoneErrorMessage}>{errorMessage}</p>}
    </div>
  );
};

export default DropzoneMobile;
