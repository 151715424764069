import React, { CSSProperties, useEffect, useState } from 'react';

// === Assets === //
import iconDelete from '@assets/images/icon-delete.svg';
import iconDeleteHover from '@assets/images/icon-delete-hover.svg';

// === Components === //
import Dropzone, { IPreviewProps, IStyleCustomization } from 'react-dropzone-uploader';

// === Helpers === //
import cn from 'classnames';

// === Styles === //
import * as styles from '@components/Dropzone/Dropzone.module.scss';

// === Types === //
import { IDropzoneExtend, IDropzonePreview } from '@components/Dropzone/Dropzone.d';

const DropzoneContent = (props) => {
  return (
    <div className={styles.dropzoneContent}>
      <span className={styles.dropzoneContentLabel}>Drag & drop or browse</span>
      <span className={styles.dropzoneContentButton}>upload images</span>
    </div>
  );
};

const DropzonePreview = ({
  meta,
  fileWithMeta,
  handleUploadImage,
  setLimitError,
}: IDropzonePreview) => {
  const { previewUrl, status } = meta;
  const { remove } = fileWithMeta;

  useEffect(() => {
    if (status === 'error_file_size') {
      handleUploadImage({ meta }, 'remove');
      setLimitError(true);
      remove();
    }
  }, [status]);

  return (
    <>
      <div
        className={cn(
          styles.dropzonePreview,
          status !== 'done' && status !== 'error_file_size' && styles.dropzonePreviewIsLoading
        )}
        style={{ backgroundImage: `url(${previewUrl})` }}
      >
        {(status === 'done' || status === 'error_file_size') && (
          <button
            type="button"
            className={styles.dropzonePreviewDelete}
            onClick={() => {
              remove();
              handleUploadImage({ meta }, 'remove');
              setLimitError(false);
            }}
          >
            <span className={styles.dropzonePreviewDeleteIcon}>
              <img src={iconDelete} alt="" />
            </span>
            <span
              className={cn(
                styles.dropzonePreviewDeleteIcon,
                styles.dropzonePreviewDeleteIconHover
              )}
            >
              <img src={iconDeleteHover} alt="" />
            </span>
          </button>
        )}
      </div>
    </>
  );
};

const DropzoneDesktop = ({
  label,
  errorMessage,
  error,
  images,
  infoText,
  limitErrorText,
  limitError,
  setLimitError,
  handleUploadImage,
  handleChangeStatus,
}: IDropzoneExtend) => {
  const imagesLimit = 10;
  const customStyles: IStyleCustomization<CSSProperties> = {
    dropzone: {
      padding: images.length >= imagesLimit ? '20px' : '20px 20px 179px',
      minHeight: 240,
      border: `1px dashed ${error ? '#EE4044' : '#000000'}`,
      overflow: 'hidden',
      borderRadius: 0,
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: 14,
    },
    inputLabelWithFiles: {
      margin: '46px 0 52px',
      padding: 0,
      width: '100%',
      backgroundColor: 'transparent',
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
  };

  return (
    <div className={styles.dropzoneWrapper}>
      {label && label !== '' && <label className={cn(styles.dropzoneLabel)}>{label}</label>}

      <Dropzone
        key="dropzoneDesktop"
        onChangeStatus={(status) => {
          handleChangeStatus(status);

          if (status.meta.status === 'done') {
            setLimitError(false);
          }
        }}
        accept="image/*"
        multiple={true}
        maxSizeBytes={15000000} // 15mb
        maxFiles={imagesLimit}
        inputContent={() => <DropzoneContent key="contentWithoutFiles" />}
        inputWithFilesContent={() => <DropzoneContent key="contentWithFiles" />}
        PreviewComponent={(props: IPreviewProps) => (
          <DropzonePreview
            {...props}
            handleUploadImage={handleUploadImage}
            setLimitError={setLimitError}
          />
        )}
        styles={customStyles}
      />
      <p className={styles.dropzoneInfoText}>{infoText}</p>

      {limitError && !error && <p className={styles.dropzoneErrorMessage}>{limitErrorText}</p>}
      {error && <p className={styles.dropzoneErrorMessage}>{errorMessage}</p>}
    </div>
  );
};

export default DropzoneDesktop;
