import React, { useState } from 'react';

// === Components === //
import DropzoneDesktop from '@components/Dropzone/DropzoneDesktop';
import DropzoneMobile from '@components/Dropzone/DropzoneMobile';

// === Types === //
import { IDropzone } from '@components/Dropzone/Dropzone.d';
import EXIF from 'exif-js';

const Dropzone = (props: IDropzone) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [limitError, setLimitError] = useState<boolean>(false);

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }) => {
    setLoading(true);

    // if (file && file.name) {
    //   EXIF.getData(file, function () {
    //     let exifData = EXIF.pretty(file);
    //     if (exifData) {
    //       console.log(exifData);
    //       console.log(EXIF.getTag(file, 'Orientation'));
    //     }
    //   });
    // }

    if (meta.status === 'done') {
      setLoading(false);
      props.handleUploadImage({ meta, file }, 'add');
    }
  };

  return (
    <div>
      <div className="hide-on-desktop">
        <DropzoneMobile
          {...props}
          infoText="Allowed file types: JPG, JPEG, PNG. Single size file limit: 10MB. File number limit: 10."
          limitErrorText="Image is too large to upload. The maximum supported image size is 15 MB."
          loading={loading}
          limitError={limitError}
          setLimitError={setLimitError}
          handleChangeStatus={handleChangeStatus}
        />
      </div>
      <div className="hide-on-mobile">
        <DropzoneDesktop
          {...props}
          infoText="Allowed file types: JPG, JPEG, PNG. Single size file limit: 10MB. File number limit: 10."
          limitErrorText="Image is too large to upload. The maximum supported image size is 15 MB."
          loading={loading}
          limitError={limitError}
          setLimitError={setLimitError}
          handleChangeStatus={handleChangeStatus}
        />
      </div>
    </div>
  );
};

export default Dropzone;
