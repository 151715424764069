import React from 'react';

// === Assets === //

// === Helpers === //
import cn from 'classnames';

// === Styles === //
import * as styles from '@components/Checkbox/Checkbox.module.scss';

// === Types === //
import { ICheckbox } from '@components/Checkbox/Checkbox.d';

const Checkbox = ({ name, label, errorMessage = '', register }: ICheckbox) => {
  return (
    <div className={cn(styles['checkboxWrapper'])}>
      <label className={cn(styles['checkboxLabel'])}>
        <input
          name={name}
          type="checkbox"
          defaultChecked={false}
          className={cn(
            styles['checkboxInput'],
            errorMessage && errorMessage !== '' && styles['checkboxInputError']
          )}
          {...register}
        />
        <div className={cn(styles['checkboxLabelWrapper'])}>{label}</div>
      </label>

      {errorMessage && errorMessage !== '' && (
        <p className={cn(styles['checkboxErrorMessage'])}>{errorMessage}</p>
      )}
    </div>
  );
};

export default Checkbox;
