import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Header from '@components/Header/Header';
import Form from '@components/Form/Form';
import Introduction from '@components/Introduction/Introduction';
import Footer from '@components/Footer/Footer';

const AddYourPhoto = () => {
  return (
    <div>
      <Helmet title="Foundation Day | Add your photo" />
      <Header />
      <Introduction page="form" />
      <Form />
      <Footer />
    </div>
  );
};

export default AddYourPhoto;
