import React from 'react';

// === Helpers === //
import cn from 'classnames';

// === Styles === //
import * as styles from '@components/Input/Input.module.scss';

// === Types === //
import { IInput } from '@components/Input/Input.d';

const Input = ({ name, label = '', placeholder = '', register, errorMessage }: IInput) => {
  return (
    <div className={styles.inputWrapper}>
      {label && label !== '' && <label className={cn(styles.inputLabel)}>{label}</label>}
      <input
        name={name}
        type="text"
        placeholder={placeholder}
        className={cn(
          styles.inputInput,
          errorMessage && errorMessage !== '' && styles.inputInputError
        )}
        {...register}
      />
      {errorMessage && errorMessage !== '' && (
        <p className={styles.inputErrorMessage}>{errorMessage}</p>
      )}
    </div>
  );
};

export default Input;
